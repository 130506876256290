.container{
    padding: 1.5rem;
    display: flex;
}

.logo{
    display: flex;
    align-items: center;
    flex: 1;
    gap: 1rem;
}

.logo>img{
    height: 2.5rem;
    width: 2.5rem;
}

.logo>span{
    font-weight: 600;
}



/* right side */

.right{
    display: flex;
    gap: 2rem;
    align-items: center;
    justify-content: center;
}

.menu{
    display: flex;
    gap: 2rem;
    font-size: 500;
    list-style: none;
}

.menu>li:hover{
    cursor: pointer;
    color: #fe956f;
}

.search{
    border-radius: 10px;
    border: 2px solid #fe956f;
    outline: none;
    width: 7rem;
    padding: 0.2rem;
    height: 30%;
}

.cart{
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
}

.bars{
    display: none;
}

@media screen and (max-width: 640px) {
    .right{
        position: absolute;
        right: 2rem;
        z-index: 9999;
        background: white;
        border-radius: 5px;
        flex-direction: column;
        color: var(--black);
        padding: 1rem;
    }

    .menu{
        flex-direction: column;
        margin-left: -2rem;
        display: none;
    }

    .bars{
        display: block;
    }

    .search{
        display: none;
    }
}

@media screen and (max-width: 856px) {
    .search{
        display: none;

    }

    .right{
        gap: 1rem;
    }
}