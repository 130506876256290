.Virtual{
    display: flex;
    margin-top: 6rem;
    margin-bottom: 6rem;
    padding: 2rem;
    justify-content: space-around;
}

.left{
    display: flex;
    flex-direction: column;
    gap: 2rem;
    position: relative;
}

.left>:nth-child(1){
    text-transform: uppercase;
    font-size: 2rem;
    display: block;
    font-weight: bold;
}

.left>:nth-child(2){
    font-size: 1.6rem;
    display: block;
    width: 20rem;
    text-transform: uppercase;
}

.left>:nth-child(3){
    font-size: 1.6rem;
    font-weight: bold;
}

.left>img{
    position: absolute;
    width: 10rem;
    bottom: 3rem;
}

/* right side */
.wrapper{
    width: 30rem;
}

@media screen and (max-width: 856px) {
    .Virtual{
        gap: 1rem;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 2rem;
        margin-bottom: 2rem;
        padding: 1rem;
        text-align: center;
    }

    .left>img{
        margin-left: 5rem;
        margin-bottom: -0.7rem;
    }
}