.container{
    display: flex;
    flex-direction: column;
    padding: 0 2rem;
    align-items: center;
    justify-content: center;
    gap: 2rem;
    position: relative;
}

.container>:nth-child(1){
    width: 8rem;
    top: -3rem;
    left: 30%;
    position: absolute;
}

.products{
    display: grid;
    grid-template-columns: 20% auto;
    width: 90%;
}

.menu{
    list-style: none;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    font-weight: 500;
    font-size: 1.3rem;
}

.menu>li:hover{
    color: var(--pink);
    cursor: pointer;
}

.list{
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    height: 25rem;
    overflow-y: scroll;
    grid-gap: 2rem;
    justify-content: space-between;
}

.product>img{
    height: 11rem;
    width: 6rem;
    top: 3rem;
}

.product{
    width: 12rem;
    height: 8rem;
    background-color: white;
    position: relative;
    overflow: hidden;
    border-radius: 1rem;
    padding: 1rem;
    display: flex;
}

@media screen and (max-width: 856px) {
    .container{
        gap: 1rem;
    }

    .container>:nth-child(1){
        display: none;
    }

    .products{
        grid-template-columns: none;
        gap: 1rem;
    }

    .menu{
        flex-direction: row;
    }
}

@media screen and (max-width: 640px) {
    .menu{
        margin-left: -2rem;
        gap: 1rem;
        flex-wrap: wrap;
        width: 100%;
        font-size: 1.2rem;
    }

    .container h1{
        font-size: 1.7rem;
        text-align: center;
    }

}